<template>
  <b-card title="Editar Estado">
    <b-form @submit.prevent>
      <validation-observer ref="form">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Código"
              label-for="codigo"
            >
              <validation-provider
                #default="{ errors }"
                name="Código"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="codigo"
                    v-model="estado.codigo"
                    type="text"
                    maxlength="15"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalEstado"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group
              label="Descrição"
              label-for="descricao"
            >
              <validation-provider
                #default="{ errors }"
                name="Descrição"
                rules="required"
              >
                <b-form-input
                  id="descricao"
                  v-model="estado.descricao"
                  type="text"
                  placeholder="Descrição"
                  maxlength="50"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>

          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Disponível"
              label-for="disponivel"
              label-class="mb-1"
            >
              <b-form-checkbox-group
                id="disponivel"
                v-model="arrayDisponivel"
                :options="disponivelEstadosOptions"
                @change="alteraEstados()"
              />
            </b-form-group>

          </b-col>
          <b-col
            cols="12"
            md="4"
            lg="3"
          >
            <b-form-group
              label="Contrato"
              label-for="contrato"
              label-class="mb-1"
            >
              <b-form-radio-group
                v-if="estado.disponivelContratos===0"
                id="contrato"
                v-model="estadoContrato"
                :options="opcoesContrato"
                value="1"
                disabled
              />
              <b-form-radio-group
                v-else
                id="contrato"
                v-model="estadoContrato"
                :options="opcoesContrato"
                value="1"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            lg="3"
          >
            <b-form-group
              label="Serviço"
              label-for="servico"
              label-class="mb-1"
            >
              <b-form-radio-group
                v-if="estado.disponivelServicos===0"
                id="servico"
                v-model="estadoServico"
                :options="opcoesServico"
                value="1"
                disabled
              />
              <b-form-radio-group
                v-else
                id="servico"
                v-model="estadoServico"
                :options="opcoesServico"
                value="1"
              />
            </b-form-group>
          </b-col>
          
          <b-col
            cols="12"
            md="1"
            lg="1"
          >
            <b-form-group
              label="Processar"
              label-for="processaFaturacao"
              label-class="mb-1"
            >
              <b-form-checkbox-group
              v-if="estado.disponivelServicos===0"
                id="processaFaturacao"
                v-model="arrayFaturacao"
                :options="faturacaoOptions"
                @change="alteraEstadoFaturacao()"
                disabled
              />
              <b-form-checkbox-group
              v-else
                id="processaFaturacao"
                v-model="arrayFaturacao"
                :options="faturacaoOptions"
                @change="alteraEstadoFaturacao()"
                
              
              />
            </b-form-group>

          </b-col>
        </b-row>
        <b-row>
          <!-- submit and reset -->
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="switchLoc()"
            >
              Gravar
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>

      </validation-observer>
    </b-form>

    <b-modal
      ref="modalEstado"
      hide-footer
      size="xl"
    >
      <selecionarEstado @estadoSelecionado="selectEstado" />
    </b-modal>
  </b-card>
</template>

<script>
// import axios from 'axios'

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BInputGroupPrepend, BInputGroup, BModal, BFormRadioGroup, BFormCheckboxGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import store from '@/store/index'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import router from '@/router'
import selecionarEstado from '../../Listagens/Estados/SelecionarEstados.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BInputGroupPrepend,
    BInputGroup,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    BFormCheckboxGroup,
    selecionarEstado,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      required,
      estadoServico: '',
      estadoContrato: '',
      arrayDisponivel: [],
      arrayFaturacao: [],
      estado: {
        codigo: '',
        descricao: '',
        disponivelServicos: 0,
        disponivelContratos: 0,
        servicoPendente: 0,
        servicoFechado: 0,
        contratoAtivo: 0,
        contratoTerminado: 0,
        processaFaturacao: 0,
      },
    }
  },
  setup() {
    const opcoesServico = [
      { text: 'Pendente', value: 'Pendente' },
      { text: 'Fechado', value: 'Fechado' },
    ]
    const opcoesContrato = [
      { text: 'Ativo', value: 'Ativo' },
      { text: 'Terminado', value: 'Terminado' },
    ]
    const disponivelEstadosOptions = [ 'Contrato','Serviços']
    const faturacaoOptions = ['Faturação']
    return {
      opcoesServico,
      opcoesContrato,
      disponivelEstadosOptions,
      faturacaoOptions,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    localize('pt', pt)
    if (router.currentRoute.params.id !== undefined) {
      this.getOneEstado()
    }
  },
  methods: {
    getOneEstado() {
      const estadoId = this.$route.params.id // Obtenha o valor do parâmetro "id" da rota atual
      const apiUrl = `/api/v1/estados/${estadoId}`

      axios.get(apiUrl)
        .then(res => { this.selectEstado(res.data) })
        .catch(error => {
          // Lide com erros aqui, se necessário
        })

      this.$forceUpdate()
    },

    switchLoc() {
      this.$refs.form.validate().then(valid => {
        if (!valid) {
          this.erroGravacao('danger', 'CheckIcon')
        } else {
          this.saveEstado()
        }
      })
    },
    saveEstado() {
      if (this.estado.disponivelServicos === 1) {
        if (this.estadoServico === 'Pendente') {
          this.estado.servicoPendente = 1
          this.estado.servicoFechado = 0
        } else if (this.estadoServico === 'Fechado') {
          this.estado.servicoPendente = 0
          this.estado.servicoFechado = 1
        }
      } else if (this.estado.disponivelServicos === 0) {
        this.estado.servicoPendente = 0
        this.estado.servicoFechado = 0
        this.estado.processaFaturacao = 0
      }

      if (this.estado.disponivelContratos === 1) {
        if (this.estadoContrato === 'Ativo') {
          this.estado.contratoAtivo = 1
          this.estado.contratoTerminado = 0
        } else if (this.estadoContrato === 'Terminado') {
          this.estado.contratoAtivo = 0
          this.estado.contratoTerminado = 1
        }
      } else if (this.estado.disponivelContratos === 0) {
        this.estado.contratoAtivo = 0
        this.estado.contratoTerminado = 0
      }
      axios.put(`/api/v1/estados/${this.estado.id}`, this.estado)
        .then(this.showToast('success', 'CheckIcon'))
      this.getEstado()
    },
    alteraEstados() {
      this.estado.disponivelContratos = 0
      this.estado.disponivelServicos = 0
      for (let i = 0; i < this.arrayDisponivel.length; i += 1) {
        if (this.arrayDisponivel[i] === 'Contrato') {
          this.estado.disponivelContratos = 1
        }
        if (this.arrayDisponivel[i] === 'Serviços') {
          this.estado.disponivelServicos = 1
        }
      }
    },
    alteraEstadoFaturacao() {
      this.estado.processaFaturacao = 0
      for (let i = 0; i < this.arrayFaturacao.length; i += 1) {
        if (this.arrayFaturacao[i] === 'Faturação') {
          this.estado.processaFaturacao = 1
        }
      }
    },
    selectEstado(row) {
      console.log(row)
      this.arrayDisponivel = []
      this.arrayFaturacao = []
      this.estadoServico = ''
      this.estadoContrato = ''
      this.estado.id = row.id
      this.estado.codigo = row.codigo
      this.estado.descricao = row.descricao
      this.estado.disponivelServicos = row.disponivelServicos
      this.estado.disponivelContratos = row.disponivelContratos
      this.estado.processaFaturacao = row.processaFaturacao
      this.estado.servicoPendente = row.servicoPendente
      if (row.disponivelServicos === 1) {
        this.arrayDisponivel.push('Serviços')
      }
      if (row.disponivelContratos === 1) {
        this.arrayDisponivel.push('Contrato')
      }
      if (row.processaFaturacao === 1) {
        this.arrayFaturacao.push('Faturação')
      }
      if (row.servicoPendente === 1) {
        this.estadoServico = 'Pendente'
      }
      if (row.servicoFechado === 1) {
        this.estadoServico = 'Fechado'
      }
      if (row.contratoAtivo === 1) {
        this.estadoContrato = 'Ativo'
      }
      if (row.contratoTerminado === 1) {
        this.estadoContrato = 'Terminado'
      }
      this.estado.servicoFechado = row.servicoFechado
      this.hidemodalEstado()
    },

    showModalEstado() {
      this.$refs.modalEstado.show()
    },
    hidemodalEstado() {
      this.$refs.modalEstado.hide()
    },
    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Registo gravado com sucesso !',
          variant,
        },

      })
    },
    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
